import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC_bk0pacj-A800OtvJyLDHAUYFaF19MIk",
    authDomain: "doctor-photos.firebaseapp.com",
    projectId: "doctor-photos",
    storageBucket: "doctor-photos.firebasestorage.app",
    messagingSenderId: "476055515330",
    appId: "1:476055515330:web:966191ab8c0855b6e68b5b",
    measurementId: "G-1GLEHWGNKD",
    databaseURL: "https://doctor-photos-default-rtdb.firebaseio.com/",
};

export const app = initializeApp(firebaseConfig);
const auth = getAuth();

const firebaseAdminLogin = async () => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      process.env.REACT_APP_ADMIN_EMAIL,
      process.env.REACT_APP_ADMIN_PASSWORD
    );
  } catch (error) {
    console.error("Firebase Admin Login Error:", error);
  }
};

firebaseAdminLogin();