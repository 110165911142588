import Home from './component/Home';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Plan from './component/Plan';
import PaymentFaileld from './component/PaymentFailed';
import PaymentSucess from './component/PaymentSuccess';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/plan" element={<Plan />}></Route>
          <Route path="/payment-cancel" element={<PaymentFaileld />}></Route>
          <Route path="/payment-success" element={<PaymentSucess />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;