import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import banner from '../assets/image/new/blackhole.jpg'
import uploadimg from '../assets/image/new/uploadimg.png'
import people from '../assets/image/features_small/pencil.png'
import lab from '../assets/image/features_small/lab.png'
import plaster from '../assets/image/features_small/plaster.png'
import couple from '../assets/image/new/couple.PNG'
import photoraphy from '../assets/image/new/photoraphy.PNG'
import girls from '../assets/image/new/girls.PNG'
import girls_group from '../assets/image/new/girls_group.webp'
import car from '../assets/image/new/car.webp'
import home from '../assets/image/new/home.webp'
import split from '../assets/image/new/split.svg'
import aivideo from '../assets/image/new/aivideo.mp4'
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { getDatabase, ref, get, set } from "firebase/database";
import { app } from "../firebase";

import firstslider from '../assets/image/new/firstslider.jpg'
import secoundslider from '../assets/image/new/secondslider.jpg'
import thirdslider from '../assets/image/new/thirdslider.jpg'
import fourslider from '../assets/image/new/fourthslider.jpg'
import fiveslider from '../assets/image/new/fiveslider.jpg'

import smfirstslider from '../assets/image/new/sm1.jpg'
import smsecoundslider from '../assets/image/new/sm2.jpg'
import smthirdslider from '../assets/image/new/sm3.jpg'
import smfourslider from '../assets/image/new/sm4.jpg'
import smfiveslider from '../assets/image/new/sm5.jpg'


const Home = ({ userData }) => {

    const navigate = useNavigate();
    const { loginWithRedirect, logout, isAuthenticated, user, isLoading } = useAuth0();
    const [activeStep, setActiveStep] = useState(0);
    const [enhancedImageUrl, setEnhancedImageUrl] = useState('');
    const [newFileName, setNewFileName] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const db = getDatabase(app);
    const [currentCredits, setCurrentCredits] = useState(0);

    const usedCredit = 1;
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            displayUploadedFile(file);
            enhanceImage(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        document.getElementById('upload-area').classList.add('drag-over');
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        document.getElementById('upload-area').classList.remove('drag-over');
    };

    const handleDrop = (event) => {
        event.preventDefault();
        document.getElementById('upload-area').classList.remove('drag-over');
        const file = event.dataTransfer.files[0];
        if (file) {
            enhanceImage(file);
        }
    };

    const displayUploadedFile = (file) => {
        const previewImage = document.getElementById('preview-image');
        previewImage.src = URL.createObjectURL(file);
        document.getElementById('uploaded-image').style.display = 'block';
        document.getElementById('headers').style.display = 'none';
        document.getElementById('upload-area').style.display = 'none';
        document.getElementById('actions-outside').style.display = 'block';
        document.getElementById('terms').style.display = 'none';
    };

    const splitToOlderImage = () => {
        document.getElementById('splittoold').style.display = 'none';
        document.getElementById('splittoNew').style.display = 'block';
        document.getElementById('enhanced-image').style.display = 'none';
        document.getElementById('preview-image').style.display = 'block';
    };

    const splitToNewImage = () => {
        document.getElementById('splittoNew').style.display = 'none';
        document.getElementById('splittoold').style.display = 'block';
        document.getElementById('preview-image').style.display = 'none';
        document.getElementById('enhanced-image').style.display = 'block';
    };

    const removeImage = () => {
        document.getElementById('uploaded-image').style.display = 'none';
        document.getElementById('headers').style.display = 'block';
        document.getElementById('upload-area').style.display = 'block';
        document.getElementById('actions-outside').style.display = 'none';
        document.getElementById('file-input').value = '';
        document.getElementById('terms').style.display = 'block';
        document.getElementById('splittoold').style.display = 'none';
        document.getElementById('splittoNew').style.display = 'none';
        document.getElementById('enhanced-image').style.display = 'none';
        document.getElementById('preview-image').style.display = 'none';
    };

    const downloadImage = () => {
        const url = enhancedImageUrl;
        const a = document.createElement('a');
        a.href = url;
        a.download = `enhanced-${newFileName}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const updateData = async (newCredits) => {
        if (!isAuthenticated || !user) return;

        try {
            const uniqId = user.sub.split("|")[1];
            if (!uniqId) throw new Error("Invalid user ID");

            const userRef = ref(db, `users/${uniqId}`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const currentData = snapshot.val();
                const updatedCredits = (currentData.availableCredits || 0) - newCredits;
                await set(userRef, {
                    ...currentData,
                    availableCredits: updatedCredits,
                });
                setCurrentCredits(updatedCredits);
            }
        } catch (err) {
            console.error("Error updating credits:", err);
        }
    };

    const enhanceImage = (file) => {
        splitToNewImage();
        document.getElementById('splittoNew').style.display = 'none';
        document.getElementById('splittoold').style.display = 'block';
        document.getElementById('preview-image').style.display = 'none';
        document.getElementById('enhanced-image').style.display = 'none';
        setIsProcessing(true);

        if (!file) {
            alert('Please select an image to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        setNewFileName(file.name);
        formData.append('model', 'Standard V2');
        formData.append('output_format', 'jpeg');
        formData.append('crop_to_fill', 'false');
        formData.append('face_enhancement', 'true');
        formData.append('face_enhancement_creativity', '0');
        axios.post(process.env.REACT_APP_TOPAZLABS_API_URL, formData, {
            headers: {
                'Accept': 'image/jpeg',
                'X-API-Key': process.env.REACT_APP_TOPAZLABS_API_KEY,
            },
            responseType: 'blob', // Ensure response is handled as binary data (image)
        })
            .then((response) => {
                setIsProcessing(false);
                const url = URL.createObjectURL(response.data);
                setEnhancedImageUrl(url);
                document.getElementById('enhanced-image').setAttribute('src', url);
                document.getElementById('enhanced-image').style.display = 'block';

                updateData(usedCredit);
                // minus cred pavan
            })
            .catch((error) => {
                setIsProcessing(false);
                console.error('Error:', error);
                alert('Failed to enhance the image.');
            });
    };

    const steps = [
        {
            id: 1,
            title: "Upload your photo",
            description: "Upload your old photo to AI Enhance.",
            linkText: "AI Enhance",
            linkHref: "#",
        },
        {
            id: 2,
            title: "Select AI Enhance",
            description:
                "In the photo editor interface, use the AI Enhance button to fix old photos.",
            linkText: "photo editor",
            linkHref: "#",
        },
        {
            id: 3,
            title: "Preview the result",
            description:
                "Preview your newly restored photo. If needed, you can make further edits with our editing tools.",
            linkText: "",
            linkHref: "",
        },
        {
            id: 4,
            title: "Download",
            description:
                "When you’re done editing, use the Export button and select Download to save the new version.",
            linkText: "",
            linkHref: "",
        },
    ];

    const handleUploadClick = () => {
        if (!isAuthenticated) {
            loginWithRedirect();
        } else {
            if (currentCredits > 0) {
                document.getElementById("file-input").click();
            } else {
                navigate('/plan');
            }
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const closeDropdown = () => {
        setDropdownOpen(true);
    };

    useEffect(() => {
        const fetchCredits = async () => {
            if (isAuthenticated) {
                const uniqId = user.sub.split("|")[1];
                const userRef = ref(db, `users/${uniqId}`);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    setCurrentCredits(snapshot.val().availableCredits || 0);
                }
            }
        };
        fetchCredits();
    }, [isAuthenticated, user]);

    const [currentImage, setCurrentImage] = useState(firstslider);
    const [clipPosition, setClipPosition] = useState(50);

    const sliderRef = useRef(null);
    const imageRef = useRef(null);

    const handleMove = (clientX) => {
        if (!sliderRef.current || !imageRef.current) return;

        let container = imageRef.current.parentElement;
        let rect = container.getBoundingClientRect();
        let percent = ((clientX - rect.left) / rect.width) * 100;
        percent = Math.max(0, Math.min(100, percent));

        setClipPosition(percent);
    };

    const handleMouseMove = (event) => handleMove(event.clientX);
    const handleTouchMove = (event) => handleMove(event.touches[0].clientX);

    const handleMouseDown = () => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", handleMouseMove);
        });
    };

    const handleTouchStart = () => {
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", () => {
            document.removeEventListener("touchmove", handleTouchMove);
        });
    };

    const changeImage = (newImage) => {
        setCurrentImage(newImage);
        setClipPosition(50); // Reset slider position
    };



    return (
        <>

            <Navbar userData={userData} currentCredits={currentCredits} />

            {/* Photo Restoration: Restore Old Photos with AI */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="heading-title">
                            <h2>Photo Restoration: Restore Old Photos With AI</h2>
                            <p>Bring those black and white, Polaroid, disposable camera, cell phone and grainy photo formats "back to life" with powerful AI photo restoration tools.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* AI hence */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 selection-set">
                            <div className="image-comparison-wrapper">
                                {/* Image Comparison Section */}
                                <div className="comparison-container">
                                    {/* Single Image */}
                                    <img
                                        ref={imageRef}
                                        src={currentImage}
                                        alt="Comparison"
                                        className="img-fluid w-100"
                                        style={{
                                            filter: `blur(${(clipPosition / 100) * 3}px)`,
                                            clipPath: `inset(0 ${100 - clipPosition}% 0 0)`,
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                    />


                                    <img
                                        src={currentImage}
                                        alt="Original"
                                        className="img-fluid"
                                    />


                                    <div
                                        ref={sliderRef}
                                        className="slider"
                                        onMouseDown={handleMouseDown}
                                        onTouchStart={handleTouchStart}
                                        style={{ left: `${clipPosition}%` }}
                                    >
                                        <div className="slider-button">
                                            <i className="fa-solid fa-arrows-left-right"></i>
                                        </div>
                                    </div>

                                </div>

                                {/* Thumbnail Section */}
                                <div className="thumbnail-container d-flex mt-4 gap-3">
                                    <img
                                        src={firstslider}
                                        className="thumbnail"
                                        onClick={() => changeImage(firstslider)}
                                    />
                                    <img
                                        src={secoundslider}
                                        className="thumbnail"
                                        onClick={() => changeImage(secoundslider)}
                                    />
                                    <img
                                        src={thirdslider}
                                        className="thumbnail"
                                        onClick={() => changeImage(thirdslider)}
                                    />
                                    <img
                                        src={fourslider}
                                        className="thumbnail"
                                        onClick={() => changeImage(fourslider)}
                                    />
                                    <img
                                        src={fiveslider}
                                        className="thumbnail"
                                        onClick={() => changeImage(fiveslider)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set">
                            <div>
                                <div className="first-right-box">
                                    <div className="headers" id="headers">
                                        <div className="images">
                                            <div className="image-item">
                                                <img src={uploadimg} alt="Before" />
                                            </div>
                                        </div>
                                        <h1>AI Enhance</h1>
                                    </div>
                                    <div
                                        className="upload-area"
                                        id="upload-area"
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        <button onClick={handleUploadClick}>
                                            <i className="fas fa-upload"></i> Browse files
                                        </button>
                                        <input
                                            type="file"
                                            id="file-input"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={handleFileUpload}
                                        />
                                        <p>or drag and drop it here</p>
                                    </div>
                                    <div id="uploaded-image">
                                        <div className="upload-image-btn">
                                            <button
                                                id="splittoold"
                                                className="split-btn"
                                                onClick={splitToOlderImage}
                                            >
                                                <img src={split} alt="" />
                                            </button>
                                            <button
                                                id="splittoNew"
                                                className="split-btn-show"
                                                onClick={splitToNewImage}
                                            >
                                                <img src={split} alt="" />
                                            </button>
                                            <button className="close-btn" onClick={removeImage}>
                                                ×
                                            </button>
                                        </div>

                                        <div className="upload-img-set center-image">
                                            {isProcessing && <p className="processing-text">Processing your image...</p>}
                                            <img
                                                id="enhanced-image"
                                                className="upload-image-set"
                                                src=""
                                                alt="Enhanced Image"
                                                style={{ display: 'none' }}
                                            />
                                            <img
                                                id="preview-image"
                                                className="upload-image-set"
                                                src=""
                                                alt="Uploaded Image"
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id="terms" className="upload-btm text-center">
                                    <p>
                                        By uploading a file, you agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                                    </p>
                                </div>

                                {/* Buttons Outside */}
                                <div className="actions-outside" id="actions-outside">
                                    {/* <button className="edit-btn" onClick={editImage}>Edit</button> */}
                                    <button className="download-btn" onClick={downloadImage}>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Your go-to tool for photo restoration */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="center-text-set">
                                <div>
                                    <img src={lab} alt=""
                                        className="animated_element animation-scale" />
                                </div>
                                <div>
                                    <h4>
                                        <a href="about.html" title="Lorem ipsum">
                                            Your go-to tool for photo restoration
                                        </a>
                                    </h4>
                                    <p>Upload any photo and let our AI technology revive every detail - from faded colors to lost resolution, automatically.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="center-text-set">
                                <div>
                                    <img src={people} alt=""
                                        className="animated_element animation-scale" />
                                </div>
                                <div>
                                    <h4>
                                        <a href="about.html" title="Lorem ipsum">
                                            Instantly enhance photos
                                        </a>
                                    </h4>
                                    <p>Upload a photo and get stunning results in seconds. Simple, fast, and fully automated restoration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="center-text-set">
                                <div>
                                    <img src={plaster} alt=""
                                        className="animated_element animation-scale" />
                                </div>
                                <div>
                                    <h4>
                                        <a href="about.html" title="Lorem ipsum">
                                            AI-powered restoration
                                        </a>
                                    </h4>
                                    <p>Advanced AI enhances your photos while giving you the option to fine-tune the results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* video section */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="video-section">
                                {/* Image Comparison Section */}
                                <div className="comparison-container">
                                    {/* Single Image */}
                                    <img
                                        ref={imageRef}
                                        src={banner}
                                        alt="Comparison"
                                        className="img-fluid w-100"
                                        style={{
                                            filter: `blur(${(clipPosition / 100) * 3}px)`,
                                            clipPath: `inset(0 ${100 - clipPosition}% 0 0)`,
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                    />


                                    <img
                                        src={banner}
                                        alt="Original"
                                        className="img-fluid"
                                    />


                                    <div
                                        ref={sliderRef}
                                        className="slider"
                                        onMouseDown={handleMouseDown}
                                        onTouchStart={handleTouchStart}
                                        style={{ left: `${clipPosition}%` }}
                                    >
                                        <div className="slider-button">
                                            <i className="fa-solid fa-arrows-left-right"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="steps-section video-section-desc content-center">
                                <div className="steps-container">
                                    <h1>How to restore old photos with AI photo restoration</h1>
                                    {steps.map((step, index) => (
                                        <div
                                            key={step.id}
                                            className={`step-item ${activeStep === index ? "active" : ""}`}
                                            onMouseEnter={() => setActiveStep(index)} // Update active step on hover
                                        >
                                            <span className="number">{step.id}</span>
                                            <div className="content">
                                                <div className="title">{step.title}</div>
                                                <div className="description">
                                                    {step.description}{" "}
                                                    {step.linkText && (
                                                        <a href={step.linkHref}>{step.linkText}</a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Save precious */}
            <section className="special-space">
                <div className="container-fluid bg-black-set">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set order-2 order-lg-1">
                                <div className="heading_text">
                                    <h2>Restore and enhance old images with AI</h2>
                                    <p>
                                        Ready to preserve cherished memories? Doctor Photos makes it simple. Our state-of-the-art AI enhances every aspect of your photos automatically. Simply upload an image and watch as every detail comes back to life.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-1 order-lg-1">
                                <div className="left-img-set">
                                    <img src={couple} alt="Save precious memories" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Instantly renew */}
            <section className="special-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="left-img-set">
                                <img src={photoraphy} alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set">
                            <div className="heading_text">
                                <h2>Instantly renew old photos and remove objects</h2>
                                <p>
                                    With Doctor Photos' AI restoration technology, transform photos in seconds without any technical expertise. Our intelligent system automatically enhances image quality while giving you control over the final results. Need to remove imperfections? Our touch-up tools help create the perfect finished photo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Restore section */}
            <section className="special-space">
                <div className="container-fluid bg-black-set">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set order-2 order-lg-1">
                                <div className="heading_text">
                                    <h2>Save precious memories and heritage with the AI Photo Restorer</h2>
                                    <p>
                                        Nothing beats the joy of preserving family history. Doctor Photos helps safeguard these precious moments by creating pristine digital archives of your memories. Transform these cherished images into beautiful prints, merchandise, and keepsakes for generations to come.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-1 order-lg-1">
                                <div className="left-img-set">
                                    <img src={girls} alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Save precious memories and heritage with the AI Photo Restorer */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="left-img-set">
                                <img src={girls_group} alt="Save precious memories" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set">
                            <div className="heading_text">
                                <h2>Save precious memories and heritage with the AI Photo Restorer</h2>
                                <p>
                                    Photo enhancement doesn't stop at restoration. Transform your enhanced images into custom merchandise - from framed prints to t-shirts, posters to memorabilia. Create lasting keepsakes that showcase your restored photos in stunning quality, perfect for gifts or personal display.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Revive black-and-white photos with color */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set order-2 order-lg-1">
                            <div className="heading_text">
                                <h2>Revive black-and-white photos with color</h2>
                                <p>
                                    Start with pristine black-and-white restoration, then explore optional colorization to add new dimension to historic images. Our AI technology helps reveal the vibrant potential in every monochrome photo while preserving its authentic character.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-1 order-lg-1">
                            <div className="left-img-set">
                                <img src={car} alt="Revive black-and-white photos with color" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Refine old photos with AI tools */}
            <section className="equal-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="left-img-set">
                                <img src={home} alt="Refine old photos with AI tools" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 right-text-set">
                            <div className="heading_text">
                                <h2>Refine old photos with AI tools</h2>
                                <p>
                                    Streamline your photo restoration process with convenient batch uploading. Process multiple photos at once through our website, or attend our "On-site Restoration Events" where we digitize entire collections in person. Perfect for preserving family albums, yearbooks, or complete photo collections while maintaining consistent quality across all images.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Home
