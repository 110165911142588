import React, { useState, useEffect } from "react";
import { getDatabase, ref, set, get } from "firebase/database";
import { app } from "../firebase";
import Navbar from "./Navbar";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./Footer";
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable, getFunctions } from "firebase/functions";

// ✅ Use Stripe Publishable Key (Not Secret Key)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Plan = () => {
    const { user, isAuthenticated } = useAuth0();
    const db = getDatabase(app);
    const functions = getFunctions(app);
    const createCheckoutSession = httpsCallable(functions, "createCheckoutSession");

    const [userData, setUserData] = useState(null);
    const [currentCredits, setCurrentCredits] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);

    // You would need the actual product ID from Stripe to be inserted here
    const productId = "prod_RgloOnLb663RT5"; // Replace with your actual product ID

    const plans = [
        { credits: 400, price: 39, pricePerCredit: 0.098 },
        { credits: 1000, price: 79, pricePerCredit: 0.079 },
        { credits: 3000, price: 199, pricePerCredit: 0.066 },
        { credits: 9000, price: 499, pricePerCredit: 0.055 },
        { credits: 20000, price: 899, pricePerCredit: 0.045 },
        { credits: 50000, price: 1999, pricePerCredit: 0.04 }
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated && user) {
                const uniqId = user.sub.split("|")[1];
                const userRef = ref(db, `users/${uniqId}`);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setUserData(userData);
                    setCurrentCredits(userData.availableCredits || 0);
                } else {
                    console.log("No user data found.");
                }
            }
        };
        fetchData();
    }, [isAuthenticated, user]);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const handleAddPlan = async () => {
        if (!selectedPlan || !isAuthenticated) return;
        setLoading(true);
        try {
            const uniqId = user.sub.split("|")[1];
            const response = await createCheckoutSession({
                productId, // Pass the product ID to the backend
                credits: selectedPlan.credits,
                userId: uniqId,
                email: userData.email,
            });
            console.log('response', response)
            if (response.data && response.data.sessionId) {
                const stripe = await stripePromise;
                await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
            } else {
                console.error("Error: Invalid Stripe session ID.");
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
        setLoading(false);
    };

    return (
        <>
            <Navbar currentCredits={currentCredits} />
            <div className="container my-5">
                <div className="row">
                    <div className="pricing-table text-center">
                        <div className="text-center mb-5">
                            <h2>Choose your plan.</h2>
                        </div>

                        <div className="d-flex justify-content-between mb-4 border-bottom">
                            {/* <span><h3>Credits Per Month</h3></span>
                            <span><h3>Price Per Month</h3></span> */}
                        </div>

                        <ul className="list-group">
                            {plans.map((plan, index) => (
                                <li
                                    key={index}
                                    className={`list-group-item d-flex justify-content-between ${selectedPlan && selectedPlan.credits === plan.credits ? 'active' : ''}`}
                                    onClick={() => handlePlanSelect(plan)}
                                >
                                    <span>{plan.credits}</span>
                                    <div className="d-flex">
                                        <span className="mx-3">${plan.pricePerCredit} Per Credit</span>
                                        <h5><strong>${plan.price}</strong></h5>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <div className="mt-4">
                            {!loading && selectedPlan ? (
                                <>
                                    <button className="btn btn-primary btn-lg" onClick={handleAddPlan}>
                                        Proceed to Payment
                                    </button>
                                </>
                            ) : (
                                // !loading &&   <h6>Please select a plan.</h6>
                                <></>
                            )}
                        </div>

                        {loading && <>
                            <h6>Please wait...</h6>
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </>}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Plan;
