import React from 'react'

const Footer = () => {
    return (
        <>
            <div class="container-fluid footer_container">
                <div className='container'>
                    <div className='row'>
                        <div class="container footer">
                            <div class="row align-items-start
                            ">
                                <div class="col">
                                    <li class="footer_banner_box super_light_blue animated_element animation-fadeIn duration-500">
                                        <h2>
                                            Health Insurance
                                        </h2>
                                        <p>
                                            <a class="icon_small_arrow right_white" href="#">Here in medicenter we have
                                                individual</a>
                                        </p>
                                    </li>
                                    <div class="footer_box">
                                        <h3 class="box_header">
                                            Medicenter Clinic
                                        </h3>
                                        <p class="info">
                                            Mauris adipiscing mauris fringilla turpis interdum sed pulvinar nisi malesuada
                                            individual.
                                        </p>
                                        <ul class="footer_contact_info_container clearfix">
                                            <li class="footer_contact_info_row">
                                                <div class="footer_contact_info_left">
                                                    Medicenter Clinic
                                                </div>
                                            </li>
                                            <li class="footer_contact_info_row icon_right_left">
                                                <div class="footer_contact_info_left">
                                                    33 Farlane Street
                                                </div>
                                                <div class="footer_contact_info_right">
                                                    +123 655 655
                                                </div>
                                            </li>
                                            <li class="footer_contact_info_row icon_right_left">
                                                <div class="footer_contact_info_left">
                                                    Keilor East
                                                </div>
                                                <div class="footer_contact_info_right">
                                                    +123 755 755
                                                </div>
                                            </li>
                                            <li class="footer_contact_info_row icon_right_left">
                                                <div class="footer_contact_info_left">
                                                    VIC 3033, Australia
                                                </div>
                                                <div class="footer_contact_info_right">
                                                    <a href="mailto:medicenter@mail.com" title="Send Email">
                                                        medicenter@mail.com
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="social_icons clearfix">
                                            <li>
                                                <a class="social_icon facebook" href="https://facebook.com/QuanticaLabs" title=""
                                                    target="_blank">
                                                    &nbsp;
                                                </a>
                                            </li>
                                            <li>
                                                <a class="social_icon twitter" href="https://twitter.com/QuanticaLabs" title=""
                                                    target="_blank">
                                                    &nbsp;
                                                </a>
                                            </li>
                                            <li>
                                                <a class="social_icon googleplus" href="#" title="">
                                                    &nbsp;
                                                </a>
                                            </li>
                                            <li>
                                                <a class="social_icon mail" href="mailto:medicenter@mail.com" title="">
                                                    &nbsp;
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <li
                                        class="footer_banner_box light_blue animated_element animation-slideRight duration-800 delay-250">
                                        <h2>
                                            Medical Records
                                        </h2>
                                        <p>
                                            <a class="icon_small_arrow right_white" href="#">Here in medicenter we have
                                                individual</a>
                                        </p>
                                    </li>
                                    <div class="footer_box">
                                        <div class=" icon_right_left">
                                            <div class="header_left">
                                                <h3 class="box_header">
                                                    Latest Posts
                                                </h3>
                                            </div>
                                            <div class="header_right">
                                                <a href="#" id="footer_recent_posts_prev"
                                                    class="scrolling_list_control_left icon_small_arrow left_white"></a>
                                                <a href="#" id="footer_recent_posts_next"
                                                    class="scrolling_list_control_right icon_small_arrow right_white"></a>
                                            </div>
                                        </div>
                                        <div class="scrolling_list_wrapper">
                                            <ul class="scrolling_list footer_recent_posts">
                                                <li class="icon_small_arrow right_white">
                                                    <a href="post.html">
                                                        Mauris adipiscing mauris fringilla turpis interdum sed pulvinar nisi
                                                        malesuada.
                                                    </a>
                                                    <abbr title="29 May 2012" class="timeago">29 May 2012</abbr>
                                                </li>
                                                <li class="icon_small_arrow right_white">
                                                    <a href="post.html">
                                                        Lorem ipsum dolor sit amat velum.
                                                    </a>
                                                    <abbr title="04 Apr 2012" class="timeago">04 Apr 2012</abbr>
                                                </li>
                                                <li class="icon_small_arrow right_white">
                                                    <a href="post.html">
                                                        Mauris adipiscing mauris fringilla turpis interdum sed pulvinar nisi
                                                        malesuada.
                                                    </a>
                                                    <abbr title="02 Feb 2012" class="timeago">02 Feb 2012</abbr>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <li class="footer_banner_box blue animated_element animation-slideRight200 duration-800 delay-500">
                                        <h2>
                                            Online Bill Pay
                                        </h2>
                                        <p>
                                            <a class="icon_small_arrow right_white" href="#">Here in medicenter we have
                                                individual</a>
                                        </p>
                                    </li>
                                    <div class="footer_box last">
                                        <div class=" icon_right_left">
                                            <div class="header_left">
                                                <h3 class="box_header">
                                                    Latest Tweets
                                                </h3>
                                            </div>
                                            {/* <div class="header_right">
                                                <a href="#" id="latest_tweets_prev"
                                                    class="scrolling_list_control_left icon_small_arrow left_white"></a>
                                                <a href="#" id="latest_tweets_next"
                                                    class="scrolling_list_control_right icon_small_arrow right_white"></a>
                                            </div> */}
                                        </div>
                                        <div class="scrolling_list_wrapper">
                                            <ul class="scrolling_list latest_tweets">
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="copyright_area clearfix">
                                <div class="copyright_left">
                                    © Copyright - <a href="https://1.envato.market/medicenter-responsive-medical-health-template"
                                        title="MediCenter Template" target="_blank">MediCenter Template</a> by <a
                                            href="https://quanticalabs.com" title="QuanticaLabs" target="_blank">QuanticaLabs</a>
                                </div>
                                <div class="copyright_right">
                                    <a class="scroll_top icon_small_arrow top_white" href="#top" title="Scroll to top">Top</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
