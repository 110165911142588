import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, set, get } from "firebase/database";
import { useAuth0 } from "@auth0/auth0-react";
import { app } from "../firebase";

import Navbar from './Navbar';
import Footer from './Footer';

const PaymentSuccess = () => {
    const { user, isAuthenticated } = useAuth0();
    const db = getDatabase(app);
    const navigate = useNavigate();
    const location = useLocation();
    
    const [timer, setTimer] = useState(5);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState(null);
    const [currentCredits, setCurrentCredits] = useState(0);
    const functions = getFunctions();
    const getCheckoutSession = httpsCallable(functions, 'getCheckoutSession');

    useEffect(() => {
        if (!isAuthenticated || !user) return;
        
        const fetchData = async () => {
            const uniqId = user.sub.split("|")[1];
            const userRef = ref(db, `users/${uniqId}`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                const userData = snapshot.val();
                setUserData(userData);
                setCurrentCredits(userData.availableCredits || 0);
            } else {
                console.log("No user data found.");
            }
        };
        fetchData();
    }, [isAuthenticated, user]);

    useEffect(() => {
        if (!isAuthenticated || !user) return;

        const urlParams = new URLSearchParams(location.search);
        let sessionId = urlParams.get('session_id');

        if (sessionId) {
            sessionId = sanitizeFirebaseKey(sessionId);
            validateSession(sessionId);
        } else {
            window.location.href = "/";
            setLoading(false);
            setError('Missing session ID');
        }

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 5000);

        const timeout = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [isAuthenticated, user, location.search, navigate]);

    const sanitizeFirebaseKey = (key) => {
        return key.replace(/[.#$[\]]/g, "_");
    };

    const validateSession = async (sessionId) => {
        try {
            const sessionRef = ref(db, `sessions/${sessionId}`);
            const sessionSnapshot = await get(sessionRef);

            if (!sessionSnapshot.exists()) {
                setError('Invalid session ID.');
                setLoading(false);
                window.location.href = "/";
                return;
            }

            const sessionData = sessionSnapshot.val();
            if (sessionData.status === 'used') {
                setError('This session has already been used.');
                setLoading(false);
                window.location.href = "/";
                return;
            }

            const credits = sessionData.credits || 0;
            fetchPaymentDetails(sessionId, sessionRef, credits ,sessionData);
        } catch (err) {
            console.error('Error validating session:', err);
            setError('Error validating payment session.');
            setLoading(false);
            window.location.href = "/";
        }
    };

    const updateData = async (newCredits) => {
        if (!isAuthenticated || !user) return;
        
        try {
            const uniqId = user.sub.split("|")[1];
            if (!uniqId) throw new Error("Invalid user ID");

            const userRef = ref(db, `users/${uniqId}`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const currentData = snapshot.val();
                const updatedCredits = (currentData.availableCredits || 0) + newCredits;
                await set(userRef, {
                    ...currentData,
                    availableCredits: updatedCredits,
                });
                setCurrentCredits(updatedCredits);
            } else {
                await set(userRef, { availableCredits: newCredits });
                setCurrentCredits(newCredits);
            }
        } catch (err) {
            console.error("Error updating credits:", err);
        }
    };

    const fetchPaymentDetails = async (sessionId, sessionRef, newCredits, sessionData) => {
        try {
            sessionData.status = 'used';
            setPaymentDetails(newCredits);
            await updateData(newCredits);
            await set(sessionRef, sessionData);

        } catch (err) {
            console.error("Error fetching payment details:", err);
            setError("Failed to fetch payment details");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="bg-light p-5 d-flex justify-content-center align-items-center">
                <div className="container bg-white p-4 rounded shadow" style={{ maxWidth: '600px' }}>
                    <div className="text-center">
                        <h1 className="text-success mb-4">Payment Successful</h1>
                        {loading ? (
                            <p className="text-muted">Fetching payment details...</p>
                        ) : error ? (
                            <p className="text-danger">{error}</p>
                        ) : (
                            <>
                                <p className="lead mb-4">Your payment has been processed successfully.</p>
                                <p><strong>Payment Status:</strong> {paymentDetails?.payment_status}</p>
                            </>
                        )}
                        <p className="text-muted mt-3">Redirecting to homepage in {timer} seconds...</p>
                        {/* <a href="/" className="btn btn-success">Go to Home</a> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PaymentSuccess;
