import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const PaymentFailed = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="bg-light p-5 d-flex justify-content-center align-items-center">
                <div className="container bg-white p-4 rounded shadow" style={{ maxWidth: '600px' }}>
                    <div className="text-center">
                        <h1 className="text-danger mb-4">Payment Failed</h1>
                        <p className="lead mb-4">
                            We were unable to process your payment.
                            <br />Please check your payment details or try again later.
                        </p>
                        <a href="/plan" className="btn btn-primary">
                            Try Again
                        </a>
                    </div>
                    {/* <div className="text-center mt-3">
                    <p>
                        Need assistance? <a href="/contact-support">Contact Support</a>
                    </p>
                </div> */}
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};

export default PaymentFailed;