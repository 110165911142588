import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getDatabase, ref, set, get } from "firebase/database"; // Import Firebase Realtime Database methods
import { app } from "../firebase"; // Ensure Firebase is initialized here
import { Link } from "react-router-dom";
import logo from "../assets/image/icon_img/header_logo.png";

const Navbar = ({ userData, currentCredits }) => {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
    const db = getDatabase(app);

    const [isLoading, setIsLoading] = useState(false);

    const addUserToFirebase = async (user) => {
        if (user) {
            try {
                const uniqId = user.sub.split("|")[1];
                const userRef = ref(db, `users/${uniqId}`);
                const snapshot = await get(userRef);

                if (!snapshot.exists()) {
                    await set(userRef, {
                        uid: uniqId,
                        name: user.name,
                        email: user.email,
                        picture: user.picture,
                        availableCredits: currentCredits || 0,
                    });
                    console.log("User added to Firebase Realtime Database successfully!");
                } else {
                    console.log("User already exists in Firebase. No action needed.");
                }

            } catch (error) {
                console.error("Error adding user to Firebase:", error);
            }
        }
    };

    // Use Effect to add user to Firebase after login
    useEffect(() => {
        if (isAuthenticated && user) {
            addUserToFirebase(user);
        }
    }, [isAuthenticated, user]);

    // Handle login with loader
    const handleLogin = async () => {
        setIsLoading(true);
        try {
            await loginWithRedirect();
        } finally {
            setIsLoading(false);
        }
    };

    // Handle logout with loader
    const handleLogout = async () => {
        setIsLoading(true);
        try {
            await logout({ returnTo: window.location.origin });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignup = async () => {
        try {
            await loginWithRedirect({
                authorizationParams: {
                    screen_hint: "signup", // Forces the signup screen to appear
                },
            });
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div className="container">
            <div className="navigation-bar">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="Logo" style={{ height: "40px" }} /> Doctor Photos
                        </Link>

                        <div className="d-flex align-items-center">
                            {!isAuthenticated && (
                                <button
                                    className="btn btn-primary mx-3"
                                    onClick={handleSignup}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Logging in..." : "Signup"}
                                </button>
                            )}

                            {!isAuthenticated ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={handleLogin}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Logging in..." : "Login"}
                                </button>
                            ) : (
                                <button
                                    className="btn"
                                    style={{ fontSize: "24px", cursor: "pointer" }}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasMenu"
                                    aria-controls="offcanvasMenu"
                                >
                                    <i className="fa fa-bars"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </nav>

                {/* Offcanvas Menu */}
                {isAuthenticated && (
                    <div
                        className="offcanvas offcanvas-end"
                        data-bs-scroll="true"
                        tabIndex="-1"
                        id="offcanvasMenu"
                        aria-labelledby="offcanvasMenuLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasMenuLabel">
                                <Link className="navbar-brand" to="/">
                                    <img src={logo} alt="Logo" style={{ height: "40px" }} /> Photo
                                    Restoration
                                </Link>
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offcanvas-body">
                            {/* User Details */}
                            <div className="dropdown-item">
                                <strong>{user?.name || "Guest"}</strong>
                            </div>

                            {/* Available Credits */}
                            <div className="dropdown-item plan-table-set">
                                <div>Available Credits</div>
                                <div><h5><strong>{currentCredits}</strong></h5></div>
                            </div>

                            {/* Menu Items */}
                            <ul className="list-unstyled">
                                <li>
                                    <Link className="dropdown-item" to="/plan">
                                        Buy Credits
                                    </Link>
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleLogout}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Logging out..." : "Logout"}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
